import { Injectable } from '@angular/core';
import { EventBusService } from '@app/core/services/event-bus/event-bus.service';
import {
  UserGQL,
  AccountStatementGQL,
  AccountStatementQuery,
  UserQuery,
} from '@app/generated/graphql';
import { Apollo } from 'apollo-angular';
import * as Sentry from '@sentry/browser';
import { StatementGenerationService } from './statement-generation.service';
import { ApolloQueryResult } from '@apollo/client/core';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class StatementDownloadService {
  private userId: string;

  constructor(
    private eventBus: EventBusService,
    private userGql: UserGQL,
    private apollo: Apollo,
    private accountStatementGQL: AccountStatementGQL,
    private statementGenerationService: StatementGenerationService
  ) {
    this.setupStatementDownload();
  }

  private setupStatementDownload(): void {
    this.userGql.fetch().subscribe((res: ApolloQueryResult<UserQuery>) => {
      this.userId = res.data.myUser.id.toString();

      // Set up subscription only after we have the user ID
      this.eventBus
        .getChannel(`RefetchAccountStatement_${this.userId}`)
        .pipe(filter((id) => typeof id === 'string' && id.length > 0))
        .subscribe((id: String) => {
          this.apollo
            .query<AccountStatementQuery>({
              query: this.accountStatementGQL.document,
              variables: { id: Number(id) },
            })
            .subscribe(
              (res: ApolloQueryResult<AccountStatementQuery>) => {
                const accountStatement = res?.data?.accountStatement;
                if (accountStatement?.templateOutput?.state === 'completed') {
                  this.statementGenerationService.completeGeneration();
                  if (
                    accountStatement?.templateOutput?.outputFiles?.length > 0
                  ) {
                    location.href =
                      accountStatement.templateOutput.outputFiles[0].v2Url;
                  }
                }
              },
              (error) => {
                console.error('Error fetching account statement:', error);
                Sentry.captureException(error);
                this.statementGenerationService.completeGeneration();
              }
            );
        });
    });
  }
}
